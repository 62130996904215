import React from "react";
import "../styles/About.css";
import AboutCard from "../components/AboutCard";
import szabinaImg from "../imgs/szabina.png";
import avatarImg from "../imgs/Avatar.png";

const About = () => {
  return (
    <>
      <section className="about_">
        <section className="about_company">
          <header className="about_header">
            <h1 className="about_h1">Cégünk</h1>
          </header>
          <article className="about_article">
            <p className="about_p">
              Vállalkozásunk 2009. évben alakult, amely szoftverfejlesztéssel
              foglalkozott, majd 2012-től a könyvelési és bérszámfejtési
              szolgáltatásokra specializálódtunk. 2018. évtől számviteli és
              adózási oktatással bővültek tevékenységeink, 2022-től pedig már
              adótanácsadási szolgáltatást is nyújtunk. Külső partnereinkkel
              való együttműködés keretében jogi és könyvvizsgálati szolgáltatás
              is igénybe vehető.
            </p>
          </article>
        </section>
        <section className="about_section">
          <header className="about_header">
            <h1 className="about_h1">Munkatársaink</h1>
          </header>
          <article className="about_article">
            <AboutCard
              imgSrc={szabinaImg}
              imgAlt="Szabina"
              name="Dr. Szolnoki Szabina"
              title="adótanácsadó, mérlegképes könyvelő, jogász"
              phone="+36-70-370-8876"
              about="Szabina közgazdasági szakközépiskolában kezdte meg tanulmányait, ahol már érezte az
              elkötelezettséget a szakma iránt. A Miskolci Egyetemen szerezte meg közgazdasági
              diplomáját, majd az Eötvös Loránd Tudomány Egyetemen folytatta jogi tanulmányait a
              vállalkozás tevékenységei mellett, ennek ellenére cum laude minősítéssel sikeresen
              megszerezte doktori címét. Mindeközben az adótanácsadói képzést is elkezdte, melyet szintén
              kiváló eredménnyel zárt.
              Könyvelőként több, mint 15 éves, adótanácsadóként pedig 3 éves szakmai tapasztalattal
              rendelkezik. Külsős cégeknél végzett oktatói tevékenysége során elsősorban adózási (ÁFA)
              területen tart előadásokat."
            />
            <AboutCard
              imgSrc={avatarImg}
              imgAlt="Edina"
              name="Szolnoki Edina"
              title="könyvelő"
              phone="+36-70-456-1355"
              about="Edina közgazdasági szakközépiskolában kezdte tanulmányait, majd felnőtt képzés keretében
              pénzügyi-számviteli ügyintéző tanfolyamon vett részt. A Miskolci Egyetemen igazságügyi
              igazgatási szakon gyarapította tudását.
              Jelenlegi tanulmányai között folyamatban van a mérlegképes könyvelői képesítés
              megszerzése. Könyvelési területen, több, mint 5 éves szakmai tapasztalattal rendelkezik,
              illetve 4 évig követeléskezelési vezetőként tevékenykedett."
            />
          </article>
        </section>
      </section>
    </>
  );
};

export default About;
