import React from "react";
import "../styles/ASZF.css";
import ASZF_PDF from "../assets/ASZF_2023_10_18.pdf";

const ASZF = () => {
  return (
    <>
      <section className="pdf">
        <iframe src={ASZF_PDF} title="ASZF" />
      </section>
    </>
  );
};

export default ASZF;
