import React from "react";
import "../styles/AboutCard.css";

const AboutCard = (props) => {
  return (
    <>
      <div className="aboutCard">
        <div className="content">
          <div className="back">
            <div className="img">
              <img src={props.imgSrc} alt={props.imgAlt} />
            </div>
            <div className="back-content">
              <div className="description">
                <header className="title">
                  <h1>{props.name}</h1>
                </header>
                <footer className="cardFooter">
                  <p>{props.title}</p>
                  <p>{props.phone}</p>
                </footer>
              </div>
            </div>
          </div>

          <div className="front">
            <div className="front-content">
              <div className="description">
                <header className="title">
                  <h1>{props.name}</h1>
                </header>
                <article className="about">{props.about}</article>
                <footer className="cardFooter">
                  <p>{props.title}</p>
                  <p>{props.phone}</p>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <article className="aboutCard_article">
        <img className="aboutCard_img" src={props.imgSrc} alt={props.imgAlt} />
        <header className="aboutCard_header">
          <h1 className="aboutCard_h1">{props.name}</h1>
        </header>
        <p className="aboutCard_p">{props.title}</p>
        <p className="aboutCard_p">{props.phone}</p>
      </article> */}
    </>
  );
};

export default AboutCard;
