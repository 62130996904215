import React from "react";
import "../styles/Navbar.css";
import logoImg from "../imgs/Logo_2023_04_03.jpg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import menuIcon30 from "../imgs/menu.svg";

const Navbar = () => {
  const navigate = useNavigate();
  const nav = (path) => {
    console.log("rerouting");
    navigate(path);
    showButtons();
  };
  // comment
  document.body.addEventListener("click", (e) => {
    if (e.target.className !== "menuIcon") {
      setShow("none");
    }
  });

  const [show, setShow] = useState("none");

  const showButtons = () => {
    if (show === "none") {
      setShow("flex");
    } else {
      setShow("none");
    }
  };

  return (
    <>
      <header className="navbar_header">
        <img className="navbar_img" src={logoImg} alt="e_sz" />
        <section className="navbar_section hide_it">
          <div className="button_div">
            <button className="navbar_button" onClick={() => nav(`/`)}>
              Kezdőlap
            </button>
          </div>
          <div className="button_div">
            <button className="navbar_button" onClick={() => nav(`/about`)}>
              Rólunk
            </button>
          </div>
          <div className="button_div dropdown">
            <button
              className="navbar_button dropbtn"
              onClick={() => nav(`/services`)}
            >
              Szolgáltatások
            </button>
          </div>
          <div className="button_div">
            <button className="navbar_button" onClick={() => nav(`/prices`)}>
              Áraink
            </button>
          </div>
          <div className="button_div">
            <button className="navbar_button" onClick={() => nav(`/contact`)}>
              Kapcsolat
            </button>
          </div>
        </section>
        <section className="navbar_section show_it">
          <img
            className="menuIcon"
            src={menuIcon30}
            alt="Menu"
            onClick={() => showButtons()}
          />
        </section>
      </header>
      <section className="buttons" style={{ display: `${show}` }}>
        <div className="button_div">
          <button className="navbar_button" onClick={() => nav(`/`)}>
            Kezdőlap
          </button>
        </div>
        <div className="button_div">
          <button className="navbar_button" onClick={() => nav(`/about`)}>
            Rólunk
          </button>
        </div>
        <div className="button_div dropdown">
          <button
            className="navbar_button dropbtn"
            onClick={() => nav(`/services`)}
          >
            Szolgáltatások
          </button>
        </div>
        <div className="button_div">
          <button className="navbar_button" onClick={() => nav(`/prices`)}>
            Áraink
          </button>
        </div>
        <div className="button_div">
          <button className="navbar_button" onClick={() => nav(`/contact`)}>
            Kapcsolat
          </button>
        </div>
      </section>
    </>
  );
};

export default Navbar;
