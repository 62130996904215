import React from "react";
import "../styles/Home.css";
import homeImg from "../imgs/laptop-g2239f193b_1920.jpg";

const Home = () => {
  return (
    <>
      <div className="home">
        <div className="home_img">
          <img className="home__img" src={homeImg} alt="home" />
        </div>
        <div className="home_container">
          <h1 className="home_title">Minden, ami számvitel!</h1>
          <p className="home_text">
            Vállalkozásunk célja, hogy az általunk végzett szolgáltatásokat
            magas szakmai színvonalon, a jogszabályok naprakész ismerete mellett
            végezzük.
          </p>
          <p className="home_text">
            Cégünk teljes körű segítséget nyújt a vállalkozások részére a
            cégalapítástól kezdve, a folyamatos működésen át egészen a cégek
            átalakulásáig, megszüntetéséig.
          </p>
          <p className="home_text">
            A számviteli és az adózási területhez kapcsolódó szolgáltatásainkat
            elsősorban digitális környezetben, papírmentesen, online formában
            végezzük. Oktatási tevékenységünk szintén online környezetben
            történik, azonban kérés esetén személyes formában is van lehetőség
            annak igénybevétére.
          </p>
          <p className="home_text">
            Szerződött ügyfeleinknek minden évben kalkulációt készítünk a
            rendelkezésre álló adózási formák figyelembevételével, amely során
            próbáljuk megtalálni a legoptimálisabb megoldásokat az adózás
            területén is.
          </p>
          <p className="about-us_text">
            Partnereink (jogi szolgáltatás és könyvvizsgálati terület)
            elérhetőségéről e-mail-ben vagy telefonon érdeklődhet.
          </p>
        </div>
      </div>
    </>
  );
};

export default Home;
