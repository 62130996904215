import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Prices from "./pages/Prices";
import Contact from "./pages/Contact";
//import NotFound from "./pages/NotFound";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Impresszum from "./pages/Impresszum";
import ASZF from "./pages/ASZF";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: ["Raleway", "Roboto"].join(","),
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Navbar />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/prices" element={<Prices />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/impresszum" element={<Impresszum />} />
            <Route path="/aszf" element={<ASZF />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>

          <Footer />
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
