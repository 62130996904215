import React from "react";
import "../styles/Impresszum.css";

const Impresszum = () => {
  return (
    <>
      <section className="impresszum">
        <header className="impresszum__header">
          <h1 className="impresszum__header__h1">Impresszum</h1>
        </header>
        <article className="impresszum__article">
          <header className="impresszum__header">
            <h3>Szolgáltató</h3>
          </header>
          <p className="impresszum__paragraph">
            <strong>Cégnév:</strong> E&Sz Accounting Könyvelő Korlátolt
            Felelősségű Társaság
            <br />
            <strong>Székhely:</strong> 1033 Budapest, Miklós utca 13. 8. em. 42.
            ajtó ajtó
            <br />
            <strong>Telefon:</strong> +36-70-370-8876
            <br />
            <strong>Email:</strong> mindenamiszamvitel@gmail.com
            <br />
            <strong>Kapcsolat:</strong> www.mindenamiszamvitel.com
            <br />
            <strong>A céget bejegyző hatóság:</strong> Fővárosi Törvényszék
            Cégbírósága
            <br />
            <strong>Cégjegyzékszám:</strong> 01-09-423062
            <br />
            <strong>Adószám:</strong> 14940349-1-41
          </p>
        </article>
        <article className="impresszum__article">
          <header className="impresszum__header">
            <h3>Tárhelyszolgáltató</h3>
          </header>
          <p className="impresszum__paragraph">
            <strong>Cégnév:</strong> DigitalOcean, LLC.
            <br />
            <strong>Cím:</strong> 101 Avenue of the Americas, 2nd Floor New
            York, NY 10013
            <br />
            <strong>Adószám:</strong> EU528002224
            <br />
            <strong>Email:</strong> contact@digitalocean.com
            <br />
            <strong>Kapcsolat:</strong> www.digitalocean.com
          </p>
        </article>
      </section>
    </>
  );
};

export default Impresszum;
