import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Footer.css";

const Footer = () => {
  const navigate = useNavigate();

  const nav = (path) => {
    console.log("rerouting");
    navigate(path);
  };

  return (
    <>
      <footer className="footer__">
        <button className="footer__button" onClick={() => nav(`/contact`)}>
          Kapcsolat
        </button>
        <button className="footer__button" onClick={() => nav(`/impresszum`)}>
          Impresszum
        </button>
        <button className="footer__button" onClick={() => nav(`/aszf`)}>
          ÁSZF
        </button>
      </footer>
    </>
  );
};

export default Footer;
