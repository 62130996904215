import React from "react";
import "../styles/GlobalCss.css";
import "../styles/Prices.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
  Konyveles,
  Berszamfejtes,
  Tanacsadas,
  Szamviteli,
  Beszamolo,
  Bevallasok,
  Vegelszamolas,
  Oktatas,
  Cegek,
  Egyeb,
} from "../components/PriceCard";

const Prices = () => {
  return (
    <>
      <div className="service-price">
        <header className="service-price_header">
          <h1>Szolgáltatásaink díjai</h1>
          <p>
            Díjaink tájékoztató jellegűek, a tényleges árképzés egyéni
            megbeszélés utján történik.
          </p>
        </header>
        <div className="PriceContainer">
          <Konyveles />

          <Berszamfejtes />

          <Tanacsadas />

          <Szamviteli />

          <Beszamolo />

          <Bevallasok />

          <Vegelszamolas />

          <Oktatas />

          <Cegek />

          <Egyeb />
        </div>
      </div>
    </>
  );
};

export default Prices;
