import React from "react";
import "../styles/Services.css";
import ServiceCard from "../components/ServiceCard";

const Services = () => {
  return (
    <>
      <section className="services_section">
        <header className="services_header">
          <h1 className="services_h1">Szolgáltatásaink</h1>
        </header>
        <article className="services_article">
          <ServiceCard
            title="Könyvelés"
            data="Könyvelési szolgáltatásunk végzése során a klasszikus könyvelési feladatok teljes körű ellátása történik. A feladatokat naprakészen végezzük, a fizetendő közterheket, illetve bevallásokat a határidőket betartva bocsátjuk a vállalkozások rendelkezésére. A bevallásokat – meghatalmazást követően – saját nevünk alatt nyújtjuk be az adóhatóságok részére."
          />
          <ServiceCard
            title="Bérszámfejtés"
            data="Bérszámfejtési szolgáltatásunk önállóan is igénybe vehető. A bérszámfejtési szolgáltatások magukban foglalják a foglalkoztatottak bejelentését, a havi számfejtési feladatok ellátását és az ehhez kapcsolódó bérügyi dokumentációk elkészítését."
          />
          <ServiceCard
            title="Tanácsadás"
            data="Tanácsadási szolgáltatásunk az adózási, a számviteli és a könyvelési területre fókuszál, így területekhez kapcsolódó kérdések megválaszolásában állunk leendő és már meglévő ügyfeleink rendelkezésére."
          />
          <ServiceCard
            title="Számviteli szabályzatok készítése"
            data="A szolgáltatás keretében az alábbi szabályzatok elkészítését vállaljuk: számviteli politika, leltárkészítési és leltározási szabályzat, értékelési szabályzat, pénzkezelési szabályzat, selejtezési szabályzat, önköltségszámítás rendjére vonatkozó szabályzat, gépjárművek üzemeltetési, használati szabályzata, cafeteria szabályzat."
          />
          <ServiceCard
            title="Beszámolókészítés"
            data="Önálló formában vállaljuk a cégek beszámolóinak elkészítését. Ezen szolgáltatásainkat elsősorban azoknak alakítottuk ki, akik az egyszerűsített végelszámolást önállóan bonyolítják le, azonban a beszámolót nem kívánják elkészíteni. Lehetőség van továbbá nem szerződött ügyfelek esetén is az éves beszámoló készítésének igénybevételére."
          />
          <ServiceCard
            title="Bevallások készítése"
            data="Mind magánszemélyek, mind vállalkozások részére vállaljuk bevallások készítését. A magánszemélyeknél elsősorban személyi jövedelemadó, általános forgalmi adó, idegenforgalmi adóbevallásokat, a vállalkozásoknál pedig általános forgalmi adó (OSA rendszerben is), összesítő nyilatkozatok, társasági adó, cégautóadó, illetve járulékbevallásokat."
          />
          <ServiceCard
            title="Végelszámolás"
            data="Egyszerűsített végelszámolás keretében – ügyvédi közreműködés nélkül – a végelszámolás kezdetétől annak befejezéséig könyvelési, bérszámfejtési, beszámolókészítési és adminisztrációs feladatokat végzünk. A végelszámolás teljes időszaka alatt támogatjuk és segítjük leendő ügyfeleinket a végelszámoláshoz kapcsolódó feladatok ellátásában is."
          />
          <ServiceCard
            title="Oktatás"
            data="Oktatási tevékenységünk keretében egyéni és csoportos felkészítést is vállalunk (magyar) számviteli, könyvviteli és adózási területen. Középiskolások, felsősoktatásban tanulók és felnőttek részére is, vizsgára történő felkészítés vagy akár gyakorlat megszerzése céljából."
          />
          <ServiceCard
            title="Cégek átvilágítása"
            data="Vállaljuk már működő vállalkozások adózási és számviteli szempontból történő átvilágítását. Az átvilágítás eredményéről minden esetben írásos dokumentációt készítünk az esetlegesen feltárt hiányosságokról."
          />
        </article>
      </section>
    </>
  );
};

export default Services;
