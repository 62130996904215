import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "../styles/PriceCard.css";
import { useState } from "react";
import { useEffect } from "react";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function Konyveles() {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{ minWidth: 345, maxWidth: 345 }}
      style={{ backgroundColor: "#adc0da" }}
      // className="price-card"
    >
      <CardHeader
        className="priceCard_h1"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title="Könyvelés"
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Table sx={{}} size="small" aria-label="a dense table">
            <TableHead style={{ borderBottom: "1px solid rgba(0, 0,0)" }}>
              <TableRow style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}>
                <TableCell
                  align="center"
                  colSpan={2}
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Egyéni vállalkozókra vonatkozó díjak
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Egyéni vállakozó - Kisadózó
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  10.000.- Ft/hótol
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Egyéni vállakozó - átalányadózó
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  15.000.- Ft/hótol
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Egyéni vállakozó - VSZJA
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  20.000.- Ft/hótol
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Egyéni vállakozás indítása
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  15.000.- Ft
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="left">
                  Egyéni vállakozás megszüntetése
                </TableCell>
                <TableCell align="right">15.000.- Ft</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table sx={{}} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={2}
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Gazdasági társaságokra vonatkozó díjak
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Betéti társaság
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  30.000.- Ft/hótol
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Korlátolt felelősségű társaság
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  35.000.- Ft/hótol
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Zártkörűen működő részvénytársaság
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  50.000.- Ft/hótol
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="left" colSpan={2}>
                  Gazdasági társaságok esetén az éves beszámoló készítésének
                  díja egy havi alapdíjnak megfelelő átalányár.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export function Berszamfejtes() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{ minWidth: 345, maxWidth: 345 }}
      style={{ backgroundColor: "#adc0da" }}
    >
      <CardHeader
        className="priceCard_h1"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title="Bérszámfejtés"
      />

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Table sx={{}} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">3.000.- Ft/fő/hó</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export function Tanacsadas() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{ minWidth: 345, maxWidth: 345 }}
      style={{ backgroundColor: "#adc0da" }}
    >
      <CardHeader
        className="priceCard_h1"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title="Tanácsadás"
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Table sx={{}} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Szerződött ügyfelek részére
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  10.000.- Ft/órától
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="left">
                  Nem szerződött ügyfelek részére
                </TableCell>
                <TableCell align="right">15.000.- Ft/órától</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export function Szamviteli() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{ minWidth: 345, maxWidth: 345 }}
      style={{ backgroundColor: "#adc0da" }}
    >
      <CardHeader
        className="priceCard_h1"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title="Számviteli szabályzatok készítése"
      />

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Table sx={{}} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">15.000.- Ft/szabályzattól</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export function Beszamolo() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{ minWidth: 345, maxWidth: 345 }}
      style={{ backgroundColor: "#adc0da" }}
    >
      <CardHeader
        className="priceCard_h1"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title="Beszámoló készítése"
      />

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Table sx={{}} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">
                  Nem szerződött ügyfelek esetén egyedi megállapodás alapján
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export function Bevallasok() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{ minWidth: 345, maxWidth: 345 }}
      style={{ backgroundColor: "#adc0da" }}
    >
      <CardHeader
        className="priceCard_h1"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title="Bevallások készítése"
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Table sx={{}} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Magánszemélyek részére
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  5.000.- Ft/bevallás
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="left">
                  Vállalkozások részére
                </TableCell>
                <TableCell align="right">10.000.- Ft/bevallás</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export function Vegelszamolas() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{ minWidth: 345, maxWidth: 345 }}
      style={{ backgroundColor: "#adc0da" }}
    >
      <CardHeader
        className="priceCard_h1"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title="Végelszámolás"
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Table sx={{}} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Egyszerűsített végelszámolás
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  100.000.- Ft
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="left">
                  Korábbi évek bevallásainak pótlása
                </TableCell>
                <TableCell align="right">2.000.- Ft/bevallás</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export function Oktatas() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{ minWidth: 345, maxWidth: 345 }}
      style={{ backgroundColor: "#adc0da" }}
    >
      <CardHeader
        className="priceCard_h1"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title="Oktatás"
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Table sx={{}} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Egyéni formában
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  4.000.- Ft/60 perc
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="left">
                  Csoportos/céges formában
                </TableCell>
                <TableCell align="right">egyedi megállapodás alapján</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export function Cegek() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{ minWidth: 345, maxWidth: 345 }}
      style={{ backgroundColor: "#adc0da" }}
    >
      <CardHeader
        className="priceCard_h1"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title="Cégek átvilágítása"
      />

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Table sx={{}} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">10.000.- Ft/óra</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export function Egyeb() {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{ minWidth: 345, maxWidth: 345 }}
      style={{ backgroundColor: "#adc0da" }}
      onClick={handleExpandClick}
      className="card"
    >
      <CardHeader
        className="priceCard_h1"
        action={
          <ExpandMore
            expand={expanded}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title="Egyéb díjak"
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Table sx={{}} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Táppénz igénylés készítése
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  5.000.- Ft/db
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Adóhatóság előtti elektronikus képviselet (nem szerződött
                  ügyfelek részére)
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  20.000.- Ft/alkalom
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Bevallások önellenőrzése
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  5.000.- Ft/bevallás
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Adóhatósági kérelem elkészítése (kivéve: fellebbezés)
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  2.000.- Ft/kérelem
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Adóhatósági fellebbezési kérelem elkészítése
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  10.000.- Ft/kérelem
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  KSH adatszolgáltatás készítése
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  5.000.- Ft/adatszolg.
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Csecsemőgondozási díj és gyermekgondozási díj igénylése
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  5.000.- Ft/db
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Változás bejelentés
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  10.000.- Ft/alkalom
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  Havi zárás, riportok készítése
                </TableCell>
                <TableCell
                  align="right"
                  style={{ borderBottom: "1px solid rgba(0, 0, 0)" }}
                >
                  20.000.- Ft/hó
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="left">
                  Adófolyószámlák havi ellenőrzése
                </TableCell>
                <TableCell align="right">10.000.- Ft/hó</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}
