import React from "react";
import "../styles/Contact.css";
import contactImg from "../imgs/kapcsolat.jpg";

const Contact = () => {
  return (
    <>
      <section className="contact">
        {/* left image, right contact details, without form, just email and phone contacts */}
        <div className="contact__left">
          <img className="contact_Image" src={contactImg} alt="contact_image" />
        </div>
        <div className="contact__right">
          <h1 className="contact__right__title">Kapcsolat</h1>
          <p className="contact__right__text">
            Amennyiben felkeltettük érdeklődését, keressen minket bizalommal
            telefonon vagy e-mailben az alábbi elérhetőségeinken.
          </p>
          <div className="contact__right__details">
            <div className="contact__right__details__item__phone">
              <h3 className="contact__right__details__item__title">Telefon:</h3>
              <div className="contact__right__details__item__phones">
                <p className="contact__right__details__item__text">
                  +36-70-370-8876
                </p>
                <p className="contact__right__details__item__text">
                  +36-70-456-1355
                </p>
              </div>
            </div>
            <div className="contact__right__details__item">
              <h3 className="contact__right__details__item__title">Email:</h3>
              <address className="contact__right__details__item__text">
                <a href="mailto:mindenamiszamvitel@gmail.com">
                  mindenamiszamvitel@gmail.com
                </a>
              </address>
            </div>
          </div>
          <h1 className="contact__right__title">Ajánlatkérés</h1>
          <p className="contact__right__text">
            Az árajánlatkérés nem jár semmilyen kötelezettséggel, ezért
            mindenképp érdemes felvenni a kapcsolatot velünk.
          </p>
          <div className="contact__right__offer">
            <p className="contact__right__offer__text">
              Ajánlatkérésével elfogadja az ÁSZF-et.
            </p>
            <a
              href="mailto:mindenamiszamvitel@gmail.com"
              className="contact__right__offer__mailto"
            >
              <button className="contact__right__offer__mailto__button">
                <h3>Ajánlatkérés</h3>
              </button>
            </a>
          </div>
          <h1 className="contact__right__title">Időpontfoglalás</h1>
          <p className="contact__right__text">
            Oktatási, adótanácsadási, vagy akár általános konzultációra való
            időpont foglalás lehetséges.
          </p>
          <div className="contact__right__offer">
            <p className="contact__right__offer__text">
              Időpontfoglalásával elfogadja az ÁSZF-et.
            </p>
            <a
              href="https://app.minup.io/book/minden-ami-szamvitel"
              className="contact__right__offer__mailto"
              target="_blank"
              rel="noreferrer"
            >
              <button className="contact__right__offer__mailto__button">
                <h3>Időpontfoglalás</h3>
              </button>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
