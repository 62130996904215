import React, { useState } from "react";
import "../styles/ServiceCard.css";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ServiceCard = (props) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Card
        sx={{ minWidth: 345, maxWidth: 345 }}
        style={{
          backgroundColor: "#adc0da",
          typography: { fontFamily: "Raleway" },
        }}
      >
        <CardHeader
          className="serviceCard_h1"
          action={
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
          title={props.title}
        />

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Table sx={{}} size="small" aria-label="a dense table">
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell className="serviceCard_p" align="center">
                    {props.data}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Collapse>
      </Card>

      {/* <article className="serviceCard_article">
        <header className="serviceCard_header">
          <h1 className="serviceCard_h1">{props.title}</h1>
        </header>
        <p className="serviceCard_p">{props.data}</p>
      </article> */}
    </>
  );
};

export default ServiceCard;
